import React, { useEffect } from 'react';

import classes from './Analytics.module.css';

import Button from '../../kommon/Button';

function Analytics({ event, reset, updateData, clear }) {
	const getProductsEearn = () => {
		let productsEarns = event?.transactionsRecacp;
		console.log({ productsEarns });
		if (productsEarns) {
			const _list = productsEarns?.map(p => {
				console.log(p);
				return (
					<div className={classes.prodLine}>
						<span className={classes.prodLine__data}>{p.name}</span>{' '}
						<span className={classes.prodLine__data}>Prezzo: {p.price}€</span>{' '}
						<span className={classes.prodLine__nr}>Pz: {p.quantity}</span>{' '}
						<span className={classes.prodLine__data}>Tot: {p.totEarn}€</span>
					</div>
				);
			});
			return _list;
		}
	};

	useEffect(() => {
		updateData();
	}, []);

	return (
		<div className={classes.container}>
			<h1 className={classes.closer} onClick={() => clear()}>
				Chiudi
			</h1>
			<div className={classes.data}>
				<div>
					<h1
						className={classes.total}
						onClick={() => console.log('Analytics cliccato')}
					>
						Totale incassi: {event?.totalEarn || 0}€
					</h1>
				</div>
				<div className={classes.specification}>
					Di cui:
					{getProductsEearn()}
				</div>
				<div>
					<Button
						clname='abort small'
						value={'AZZERA RIASSUNTO PARZIALE'}
						action={reset}
					/>
				</div>
			</div>
		</div>
	);
}

export default Analytics;
