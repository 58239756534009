import { useCallback, useReducer } from 'react';

import Input from '../utils/Inputs/Input';

const formReducer = (state, action) => {
	switch (action.type) {
		case 'INPUT-CHANGE':
			let formIsValid = true;
			for (const inputId in state.inputs) {
				if (inputId === action.inputId) {
					formIsValid = formIsValid && action.isValid;
				} else {
					formIsValid = formIsValid && state.inputs[inputId].isValid;
				}
			}
			return {
				inputs: {
					...state.inputs,
					[action.inputId]: {
						...state.inputs[action.inputId],
						value: action.value,
						isValid: action.isValid,
						// ...action,
					},
				},
				isValid: formIsValid,
			};

		case 'SET-DATA':
			return {
				inputs: action.inputs,
				isValid: action.formIsValid,
				// ...action,
			};
		default:
			return state;
	}
};

export const useForm = (initialInputs, initialFormValidity) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: initialInputs,
		isValid: initialFormValidity,
	});

	const inputHandler = useCallback((id, value, isValid) => {
		// console.log({ id });
		// console.log({ value });
		// console.log({ isValid });
		dispatch({
			type: 'INPUT-CHANGE',
			value: value,
			isValid: isValid,
			inputId: id,
		});
	}, []);

	const setFormData = useCallback((inputData, formValiduty) => {
		// console.log({ inputData });
		// console.log({ formValiduty });
		dispatch({
			type: 'SET-DATA',
			inputs: inputData,
			formIsValid: formValiduty,
		});
	}, []);

	const setInputs = () => {
		let inputs = formState.inputs;
		let keys = Object.keys(formState.inputs);

		const inputsVisual = [];

		keys.map(k => {
			let i = inputs[k];

			let _action = null;
			switch (i.type) {
				case 'checkbox':
					_action = () => {
						let _val = document.getElementById(k).checked;
						// console.log('here');
						inputHandler(k, _val, true);
					};
					break;

				default:
					break;
			}

			if (i.hide) {
				return;
			}

			inputsVisual.push(
				<Input
					key={k + '_main'}
					id={k}
					element={i.el}
					type={i.type}
					label={i.label}
					validators={i.validator}
					errorText={i.errorText || 'Campo obbligatorio'}
					onInput={inputHandler}
					initValue={i.initValue}
					initIsValid={i.initIsValid}
					style={i.style || null}
					width={i.width}
					placeholder={i.placeholder || ''}
					list={i.list || []}
					name={i.name || null}
					driver={i.driver || null}
					onClick={_action}
				/>
			);
		});
		return inputsVisual;
	};

	return [formState, inputHandler, setFormData, setInputs];
};
