import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';

import classes from './Account.module.css';

import { UserCxt } from '../../cxt/ctxUser';

import Button from '../../kommon/Button';
import Find from '../../utils/Inputs/Find';

import Login from '../Login/Login';

import EventDetail from './EventDetail/EventDetail';
import EventNew from './EventNew/EventNew';

import { useHttpClient } from '../../hooks/http-hooks';
import LoadingSpinner from '../../utils/LoadingSpinner';
import ErrorModal from '../../utils/ErrorModal';

function Account({ clear }) {
	const LS_Area = useContext(UserCxt).LS_Area;
	const [user, setUser] = useContext(UserCxt).user;
	const [company, setCompany] = useContext(UserCxt).company;
	const [event, setEvent] = useContext(UserCxt).event;
	const [forceUserLogout, setForceUserLogout] =
		useContext(UserCxt).handleUserLogout;

	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	/**
	 * -----------------------------------------------
	 * Gestione ricaricamento FIND
	 * -----------------------------------------------
	 */
	const [findEvents, setFindEvents] = useState(null);
	const [lastCompany, setLastCompany] = useState(company);
	const [reloadFindEvents, setReloadFindEvents] = useState(false);

	useEffect(() => {
		if (!findEvents) {
			setFindEvents(
				<Find
					url={`event/comapnyEventsList/${company?._id}`}
					driver={'name'}
					inputId={'eventSelector'}
					label={`Evento`}
					initialValue={event?.name || ''}
					setRes={setEvent}
					resName={null}
					isArray={true}
					errorText={'none'}
				/>
			);
		}
	}, [findEvents]);

	useEffect(() => {
		if (lastCompany?.name !== company?.name || reloadFindEvents === true) {
			setEvent(null);
			setLastCompany(company);
			setReloadFindEvents(false);
			setFindEvents(null);
		}
	}, [company, reloadFindEvents]);

	/**
	 * -----------------------------------------------
	 * Nuovo evento
	 * -----------------------------------------------
	 */

	const [showEventNew, setShowEventNew] = useState(false);

	const handleShowEventNew = (reload = false) => {
		if (reload === true) {
			setReloadFindEvents(true);
		}
		setShowEventNew(!showEventNew);
	};

	const openNewEvent = () => {
		const eventDetail = (
			<EventNew user={user} company={company} clear={handleShowEventNew} />
		);

		return ReactDOM.createPortal(eventDetail, document.getElementById('card'));
	};

	/** <-- Nuovo evento */

	/**
	 * -----------------------------------------------
	 * Dettagli evento
	 * -----------------------------------------------
	 */

	const [showEventDetails, setShowEventDetails] = useState(false);

	const handleShowEventDetails = () => {
		setShowEventDetails(!showEventDetails);
	};

	const openEventDetail = () => {
		const eventDetail = (
			<EventDetail user={user} event={event} clear={handleShowEventDetails} />
		);

		return ReactDOM.createPortal(eventDetail, document.getElementById('card'));
	};

	/** <-- Dettagli evento */

	const saveSelectedData = async () => {
		const newUser = await sendRequest(
			'authentication/updateSelected',
			'POST',
			{ userId: user._id, companyId: company._id, eventId: event._id },
			{ 'Content-Type': 'application/json' }
		);

		setUser(newUser);

		clear();
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{showEventNew && openNewEvent()}
			{showEventDetails && openEventDetail()}
			<div className={classes.wrapper}>
				<Login />
				<div>
					<Find
						url={`company/userCompanysList/${user._id}`}
						driver={'name'}
						inputId={'companySelector'}
						label={`Titolare dell'evento:`}
						initialValue={company?.name || ''}
						setRes={setCompany}
						resName={null}
						isArray={true}
						errorText={'none'}
					/>
					{company && findEvents}

					{event && company.isAdmin && (
						<Button value={'Vedi dettagli'} action={handleShowEventDetails} />
					)}
				</div>
				<div>
					<Button value={'Chiudi'} clname={'small abort'} action={clear} />
					<Button
						value={'Salva'}
						clname={'confirm'}
						action={saveSelectedData}
					/>
				</div>
				{company && company.isAdmin && (
					<Button value={'Crea nuovo evento'} action={handleShowEventNew} />
				)}
			</div>
		</React.Fragment>
	);
}

export default Account;
