import React from 'react';

import classes from './EventNew.module.css';

import { useForm } from '../../../hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../../utils/validators';

import { useHttpClient } from '../../../hooks/http-hooks';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ErrorModal from '../../../utils/ErrorModal';

import Button from '../../../kommon/Button';

function EventNew({ user, company, event, reload, clear }) {
	const [formState, inputHandler, setFormData, setInputs] = useForm({
		name_Event: {
			value: '',
			isValid: false,
			el: 'input',
			type: 'text',
			label: 'Titolo',
			validator: [VALIDATOR_REQUIRE()],
			initValue: event?.name || '',
			initIsValid: event?.name ? true : false,
			placeholder: 'Titolo',
		},
	});

	const { clearError, error, isLoading, sendRequest } = useHttpClient();

	const saveData = async () => {
		const oldUsersList = [];
		if (event?.activeUserIdList) {
			oldUsersList = [...event?.activeUserIdList];
		}
		if (oldUsersList[0]?.refUser?.name !== user.name) {
			oldUsersList.unshift({
				refUser: user._id,
				authorizations: [],
				nickName: 'Administrator',
			});
		}

		await sendRequest(
			'event/newEvent',
			'POST',
			{
				name: formState.inputs.name_Event.value,
				companyid: company._id,
				activeUserIdList: oldUsersList,
			},
			{ 'Content-Type': 'application/json' }
		);

		clear(true);
	};

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			<div className={classes?.background} onClick={clear} />
			<div className={classes?.wrapper}>
				<div>{setInputs()}</div>
				<div>
					<Button clname={'abort'} value={'Chiudi'} action={clear} />
					<Button
						clname={'confirm'}
						value={'Salva'}
						action={saveData}
						// action={() => console.log(formState.inputs.name_Event.value)}
						disabled={!formState.isValid}
					/>
				</div>
			</div>
			;
		</React.Fragment>
	);
}

export default EventNew;
